import React, { useContext } from 'react';
import Header from './Header';
import { ComingSoon } from '../pages/landing';
import Alert from '../components/alerts';
import AlertsContext from '../contexts/AlertsContext';

const AppLayout = ({ children }) => {
  const { msgs } = useContext(AlertsContext);

  return (
    <div className="w-screen h-screen flex flex-col bg-k1475-lighter justify-start align-center overflow-y-auto overflow-x-hidden custom-scrollbar">
      <Header/>
      <main className="relative flex-1">
        {children}
        <div className="fixed top-[150px] right-8 w-[250px] max-w-1/2">
          {
            msgs.map((msg) => {
              return (
                <Alert key={msg.id} {...msg}/>
              );
            })
          }
        </div>
      </main>
      <footer>
        {/* <section className="w-full p-4 flex flex-row justify-center items-center">
          
        </section> */}
      </footer>
      <ComingSoon/>
    </div>
  )
};

export default AppLayout;