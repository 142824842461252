import { differenceInMilliseconds, isBefore } from 'date-fns';
import React, { useState } from 'react';
import useInterval from '../../hooks/useInterval';

const LAUNCH_DATE = process.env.REACT_APP_LAUNCH_DATE;
// const LAUNCH_DATE = "Thu 23 Jun 2022 14:56:20 GMT+0800 (Singapore Standard Time)";
const HAS_LAUNCED = LAUNCH_DATE ? isBefore(new Date(LAUNCH_DATE), new Date()) : true;

const isWithin10Seconds = (ms) => {
  return ms <= ((10 + 1) * 1000);
}

const LaunchCountdown = ({ onLaunched }) => {
  const [, setWithin10Secs] = useState(false);
  const [, setCountdown] = useState(false);
  // const [launchDate, setLaunchDate] = useState(process.env.REACT_APP_LAUNCH_DATE ? new Date(process.env.REACT_APP_LAUNCH_DATE) : false);
  const [launchDate, setLaunchDate] = useState(new Date(LAUNCH_DATE));
  useInterval(() => {
    console.log(launchDate);
    if(launchDate) {
      const remainingMS = differenceInMilliseconds(launchDate, new Date());
      console.log(remainingMS);
      if(isWithin10Seconds(remainingMS)) {
        setWithin10Secs(true);
        setCountdown(Math.floor(remainingMS/1000));
        if(remainingMS < (1 * 1000)) {
          setLaunchDate(false);
          onLaunched(true);
        }
      }
    }
  }, 1000);

  return (
    <div className="fixed w-screen h-screen bg-black text-white z-50 flex flex-col">
    </div>
  );
};


const ComingSoon = () => {
  const [hasLaunched, setHasLaunch] = useState(HAS_LAUNCED);
  return hasLaunched ? null : <LaunchCountdown onLaunched={setHasLaunch}/>
};

export default ComingSoon;