import React, { useCallback, useState } from 'react';
import { getTime } from 'date-fns';

const AlertsContext = React.createContext({});

export const AlertsProvider = ({ children }) => {
  const [msgs, setMsgs] = useState([]);

  const removeMsg = useCallback((id) => {
    setMsgs(currMsgs => currMsgs.filter(value => value.id !== id));
  }, []);

  const addMsg = useCallback(
  /**
   * @typedef {Object} Link
   * @property {string} to
   * @property {string} text
   * 
   * @typedef {Object} MsgObject 
   * @property {string} text
   * @property {'success' | 'error'} type
   * @property {Link} extLink
   * @property {Link} link
   * @property {Link} hashLink
   * 
   * @param {MsgObject} param0 
   */
  function ({ text, type, extLink, link, hashLink }) {
    const msgId = getTime(new Date());
    setMsgs(currMsgs => [
      ...currMsgs,
      {
        text,
        type,
        id: msgId,
        extLink,
        link,
        hashLink,
      }
    ]);

    setTimeout(() => removeMsg(msgId), 5000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertsContext.Provider value={{ msgs, addMsg }}>
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsContext;