import React, { useCallback } from 'react';
// import { ArrowCircleRightIcon } from '@heroicons/react/solid'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const CustomButton = ({ 
  onClick, 
  disabled, 
  toHash,
  to,
  target = "_self",
  text, 
  btnClassName = '', 
  txtClassName = '', 
  arrowClassName = '',
  isLoading = false
}) => {
  const handleBtnClick = useCallback((ev) => {
    if(!toHash && !to && onClick) {
      onClick(ev);
    }
  }, [toHash, to, onClick]);

  return (
    <button
      onClick={handleBtnClick}
      disabled={disabled}
      className={`
        custom-button
        ${btnClassName}
      `}
      data-loading={isLoading}
    >
    {
      target === '_blank' ?
      <a href={to} className="inline-flex items-center" target={target} rel="noreferrer">
        <p className={txtClassName}>{text}</p>
        {/* <ArrowCircleRightIcon
          className={`custom-button-arrow ${arrowClassName}`}
          aria-hidden="true"
        /> */}
      </a> :
      toHash ?
      <HashLink
        to={toHash}
        className="inline-flex items-center"
        smooth
      >
        <p className={txtClassName}>{text}</p>
        {/* <ArrowCircleRightIcon
          className={`custom-button-arrow ${arrowClassName}`}
          aria-hidden="true"
        /> */}
      </HashLink> :
      to ?
      <Link
        to={to}
        target={target}
        className="inline-flex items-center"
      >
        <p className={txtClassName}>{text}</p>
        {/* <ArrowCircleRightIcon
          className={`custom-button-arrow ${arrowClassName}`}
          aria-hidden="true"
        /> */}
      </Link> :
      <>
      <p className={txtClassName}>
        {text}
      </p>
      {
        isLoading ?
        <svg className={`custom-button-spin ${arrowClassName} animate-spin -ml-1 mr-3 h-5 w-5 text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> :
        null
        // <ArrowCircleRightIcon
        //   className={`custom-button-arrow ${arrowClassName}`}
        //   aria-hidden="true"
        // />
      }
      </>
    }
    </button>
  );
};

export default CustomButton;