import React from 'react';
import { ReactComponent as K1475Logo } from '../assets/img/k1475-Logo.svg';

const Logo = () => {
  return (
    <div className="custom-logo">
      <K1475Logo className="aspect-[16/9] h-10 w-auto"/>
    </div>
  );
};

export default Logo;