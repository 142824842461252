import { Error404, Error500 } from './pages/errors';
import Home from './pages/Home';

const routes = {
  HOME: {
    link: '/',
    name: 'Home',
    element: Home,
  },
  ERRORS: {
    link: 'errors',
    name: 'Errors',
    element: Error500,
    routes: {
      ERROR_404: {
        link: '404',
        name: 'Error404',
        element: Error404,
      },
    },
  },
};

export default routes;