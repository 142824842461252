import { } from './polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Spinner from './components/loading/Spinner';
import { Web3ReactProvider } from '@web3-react/core';
// import { MetamaskProvider } from './contexts/MetamaskContext';
import Web3 from 'web3';
import { WalletProvider } from './contexts/WalletContext';
import { ErrorBoundary } from './components/errors';
import { AlertsProvider } from './contexts/AlertsContext';

function getLibrary(provider) {
  return new Web3(provider)
}


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner/>}>
      <BrowserRouter>
        <ErrorBoundary>
          <AlertsProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <WalletProvider>
                <App/>
              </WalletProvider>
            </Web3ReactProvider>
          </AlertsProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
