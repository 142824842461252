import React from 'react';
import { ReactComponent as K1475Logo } from '../../assets/img/k1475-Logo.svg';

const ErrorScreen = ({ title, header, description, children }) => {
  return (
    <div className="w-100 h-full flex justify-center items-center flex-col px-8">
      <K1475Logo className="aspect-[16/9] h-40 w-auto mb-8"/>
      <h2 className="text-xl my-2 text-k1475-darker font-semibold tracking-wide uppercase">{title}</h2>
      <h1 className="text-3xl font-bold text-gray-900">{header}</h1>
      <p className="mt-4 max-w-2xl text-xl text-center text-gray-500 lg:mx-auto">{description}</p>
      <div className="flex flex-row justify-between items-center my-4">
        {children}
      </div>
    </div>
  )
};

export default ErrorScreen;
