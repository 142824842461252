import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TEXTS } from '../data/texts';
import { ReactComponent as K1475Logo } from '../assets/img/k1475-Logo.svg';
import WalletButton from '../components/buttons/WalletButton';
import { Minter } from '../web3/Minter';
import { WalletContext } from '../contexts/WalletContext';

const Home = () => {
  const [remainingSupply, setRemainingSupply] = useState({ loading: true, value: 0 });
  const { nftPrice, isWhitelisted } = useContext(WalletContext);


  useEffect(() => {
    const getSupply = async () => {
      setRemainingSupply({ loading: true, value: 0 });
      const minter = new Minter();
      const remSupply = await minter.getRemainingSupply();
      console.log(remSupply);
      setRemainingSupply({ loading: false, value: remSupply });
    }
    getSupply();
  }, []);
  
  const myUtilities = useMemo(() => {
    return TEXTS.UTILITIES.reduce((utilities, utility) => {
      return [
        ...utilities, {
          ...utility,
          descriptions: utility.description.split('\n'),
        }
      ];
    }, []);
  }, []);

  return (
    <div className="">
      <section id="home" className="page-section mb-80 sm:mb-20 md:mb-0">
        <K1475Logo className="aspect-[16/9] w-[calc(100%-20px)] md:w-[500px] h-auto my-20"/>
        <div className="flex flex-row justify-center items-center text-xl mb-10 w-[calc(100%-20px)]">
          <h1>Remaining Supply {remainingSupply.value} &#8226; NFT Price: {isWhitelisted ? 'FREE' : `${nftPrice} MATIC`}</h1>
        </div>
        <WalletButton/>
      </section>
      {/* <section id="about" className="page-section">
        ABOUT PAGE
      </section> */}
      <section id="utilities" className="page-section mb-20">
        <div className="w-full flex flex-col items-center justify-center mt-14 md:mt-28 relative">
          <ul className="relative border-l-[10px] border-k1475-dark max-w-[calc(100%-40px)] md:max-w-[500px] py-8">
            <div className="absolute w-6 h-6 rounded-full bg-k1475-dark border-k1475-darker border-4 top-0 left-0 transform -translate-x-[calc(50%+5px)] -translate-y-1/2"/>
            <div className="absolute w-6 h-6 rounded-full bg-k1475-dark border-k1475-darker border-4 bottom-0 left-0 transform -translate-x-[calc(50%+5px)] translate-y-1/2"/>
            {myUtilities.map((utility) => {

              return (
                <li key={utility.title} className="mb-10 ml-32 relative">
                  <div className="absolute w-10 h-10 rounded-full -left-32 transform -translate-x-[calc(50%+5px)] bg-k1475-darker"/>
                  <h2 className="text-2xl font-semibold text-k1475-darker">{utility.title}</h2>
                  {
                    utility.descriptions.map((description, idx) => {
                      return <p key={idx} className="text-2xl font-octosquares text-k1475-dark">{description}</p>
                    })
                  }
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Home;