export const DEV_IP = '1';
const BLOCKCHAIN_MODE = 'PROD';

const CHAIN_IDS = {
  PROD: [137],
  // TEST: [80001],
  TEST: [80001],
};

const MINTER_ADDRESSES = {
  PROD: '0x10653AC088926eAB022Bf54B1002CB1C79da50F0',
  TEST: '0xbB1989a790f3D3eE05E93e1547aa92ba9E67ebc3',
};

export const NETWORK_HEX_IDS = {
  80001: '0x13881',
  137: '0x89'
}

const MINTING_COSTS = {
  TEST: 1,
  PROD: 222,
}

const LIKHA_CONFIGS = {
  TEST: {
    SUBDOMAIN: 'test.',
  },
  PROD: {
    SUBDOMAIN: 'beta.',
  },
}

const TestMinterJSON = require(`./web3/jsons/test.json`);
const ProdMinterJSON = require(`./web3/jsons/prod.json`);
// const LocalMinterJSON = require(`./web3/jsons/local.json`);
export const LIKHA_BASE_URL = `https://${LIKHA_CONFIGS[BLOCKCHAIN_MODE].SUBDOMAIN}likhanft.io/nft/`;


const BLOCKCHAIN_CONFIGS = {
  PROD: {
    MINTER_ADDRESS: MINTER_ADDRESSES.PROD,
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.PROD,
    MINTING_COST: MINTING_COSTS.PROD,
    MINTER_JSON: ProdMinterJSON,
  },
  TEST: {
    MINTER_ADDRESS: MINTER_ADDRESSES.TEST,
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.TEST,
    MINTING_COST: MINTING_COSTS.TEST,
    MINTER_JSON: TestMinterJSON,
  }
};

export const BLOCKCHAIN = BLOCKCHAIN_CONFIGS[BLOCKCHAIN_MODE === 'PROD' ? 'PROD' : 'TEST'];
