import { BLOCKCHAIN } from "../setupConfig";
import web3utils from "web3-utils";
var Web3 = require("web3");

class Minter { 
  constructor(contractAddress = BLOCKCHAIN.MINTER_ADDRESS){
    this.contract_address = contractAddress;
    const web3 = new Web3(window.ethereum);
    this.ContractObj = new web3.eth.Contract(BLOCKCHAIN.MINTER_JSON.abi ?? [], contractAddress);
  }

  // JasonCruz :
  async mintNFT() {
    return this.ContractObj.methods.mintToken().encodeABI();
  }

  async getRemainingSupply() {
    const remainingSupply = await this.ContractObj.methods.remainingSupply().call();

    return remainingSupply.toString();
  }

  async getNFTPrice() {
    const nftPrice =  await this.ContractObj.methods.price().call();
    
    return web3utils.fromWei(nftPrice);
  }

  async amIAMember(address) {
    const isAMember = await this.ContractObj.methods.isATeamMember(address).call();
    return isAMember;
  }

  async retrieveTokenURI(tokenID) {
    let retry = true;
    let tokenURI = null;
    
    while(retry) {
      try {
        tokenURI = await this.ContractObj.methods.tokenURI(tokenID).call();
        retry = false;
      } catch(err) {
        retry = true;
      }
    }

    return tokenURI;
  }

  async isMintingEnabled() {
    // const isEnabled = await this.ContractObj.methods.isMintEnabled().call();

    return true;
  }
}

export { Minter };