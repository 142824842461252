import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3Wallet } from '../../hooks/useWeb3Wallet';
import { Minter } from '../../web3/Minter';
import web3utils from 'web3-utils';
import { LIKHA_BASE_URL } from '../../setupConfig';
// import { AppContext } from '../../App';
import CustomButton from './CustomButton';
import AlertsContext from '../../contexts/AlertsContext';
import axios from 'axios';

const WalletButton = ({ buyText = "GET NOW", txtClassName = "", btnClassName = "", arrowClassName = "" }) => {
  const { 
    connect, 
    account,
    isActive, 
    chainValid,
    userTokenID,
    getTXReceipt,
    shouldDisable,
    setUserTokenID,
    requestChainChange, 
    sendTransactionWithValue,
    setIsMinting, isMinting,
    isMintingEnabled
  } = useWeb3Wallet();
  const { addMsg } = useContext(AlertsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isOnMintingSite = useMemo(() => location.pathname === '/', [location]);
  const [, setMintError] = useState(false);

  const { isConnected } = useMemo(() => {
    return {
      isConnected: isActive && account,
    }
  }, [isActive, account]);

  const handleWalletClick = useCallback(async () => {
    if(userTokenID) {
      window.open(`${LIKHA_BASE_URL}${userTokenID}`, '_blank');
      return;
    }
    if(isOnMintingSite) {
      if(!isConnected) {
        await connect();
      }
      let isNowValid = chainValid;
      if(!isNowValid) {
        isNowValid = await requestChainChange();
      }

      if(isNowValid) {
        if(!isMintingEnabled) {
          addMsg({ text: 'Minting is not yet open', type: 'error' });
          return;
        }
        const minter = new Minter();
        const mintABI = await minter.mintNFT();
        setMintError(false);
        setIsMinting(true);
        try {
          const tx = await sendTransactionWithValue(mintABI, account);
          // console.group('tx', tx);
          const hash = await getTXReceipt(tx);
          // console.group('hash', hash);
          if(hash.status === 1) {
            const tokenID = web3utils.hexToNumber(hash.logs[0].topics[3]);
            // window.open();
            const tokenURI = await minter.retrieveTokenURI(tokenID);
            const tokenInfo = await axios.get(tokenURI);
            const nftID = tokenInfo.data.id;
            window.localStorage.setItem(account, nftID);
            setUserTokenID(nftID);
            addMsg({ text: 'Successfully minted your NFT!', type: 'success', extLink: { to: `${LIKHA_BASE_URL}${nftID}`, text: 'Go to NFT' }});
            // setTimeout(() => addMsg(`Here's your token url: ${tokenURI}`, 'success'), 50);
          } else {
            throw new Error('Unable to mint, please try again later')
          }
        } catch(err) {
          addMsg({ text: err?.message || 'Failed to get Platinum Pass', type: 'error' });
          setMintError(err);
        } finally {
          setIsMinting(false);
        }
      }
    } else {
      navigate('/#home');
    }
  }, [userTokenID, isOnMintingSite, isConnected, chainValid, connect, requestChainChange, isMintingEnabled, setIsMinting, addMsg, sendTransactionWithValue, account, getTXReceipt, setUserTokenID, navigate]);

  return (
    <CustomButton
      onClick={handleWalletClick}
      disabled={shouldDisable}
      text={userTokenID ? 'Go to NFT' : isOnMintingSite ? isConnected ? buyText : 'CONNECT TO WALLET' : 'GO TO MINT'}
      to={isOnMintingSite ? undefined : "/platinum-pass"}
      btnClassName={btnClassName}
      txtClassName={txtClassName}
      arrowClassName={arrowClassName}
      isLoading={isMinting}
      // className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
    />
  );
};

export default WalletButton;