import React, { Suspense } from 'react';
import AppLayout from './layouts/AppLayout';
import { Route, Routes } from 'react-router-dom';
import { ErrorNotFound } from './pages/errors';
import Spinner from './components/loading/Spinner';
import routes from './routes';

function App() {
  return (
    <AppLayout>
      <Suspense fallback={<Spinner/>}>
      <Routes>
        <Route index element={<routes.HOME.element/>}/>
        <Route path={routes.ERRORS.link}>
          <Route index element={<routes.ERRORS.element/>}/>
          <Route path={routes.ERRORS.routes.ERROR_404.link} element={<routes.ERRORS.routes.ERROR_404.element/>}/>
        </Route>
        <Route path="*" element={<ErrorNotFound/>}/>
      </Routes>
      </Suspense>
    </AppLayout>
  );
}

export default App;
