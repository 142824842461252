export const TEXTS = {
  WHO_ARE_WE: 'A tight- knit, fun group of innovators, creatives, collectors and blockchain experts uniting with a mission: to onboard more local business and consumers to the ever growing space of blockchain.',
  HOW_TO_JOIN: `To join the community, one must have “The BLOCK2BLOCK Platinum NFT Pass” as a membership keycard. This will give you access on our Discord Server, exclusive events, gain real world utilities and a lot more once you're a member.`,
  HOW_TO_GET_ACCESS: `To get access to THE EXCLUSIVE UTILITIES for BLOCK2BLOCK PLATINUM PASS , please connect YOUR WALLET TO AVAIL THE MEMBERSHIP PASS.`,
  UTILITIES: [
    {
      title: 'Phase 1',
      description: `Free 1 beverage after minting
      10% Discount forever
      
      Buy 1 take 1 on your bday
      
      30% Off on all pastries by 6pm onwards daily
      
      Monthly surprise perks`,
    },
    {
      title: 'Phase 2',
      description: `Earn loyalty points
      Redeem your points
      Store Expansions`,
    },
    {
      title: 'Phase 3',
      description: 'Gamification',
    },
    {
      title: 'Phase 4',
      description: 'To be announced',
    },
  ]
}